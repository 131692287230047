<template>
	<!-- 变形测点 -->
	<div class="deformationPoint-box">
		<div class="left-box">
			<div class="box-title">
				<span class="title-text">测点线列表</span>
				<div class="right">
					<el-select v-model="state" placeholder="请选择状态">
						<el-option v-for="item in stateOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
					</el-select>
					<div class="button-box">
						<el-button plain type="primary" size="small" :disabled="!judgment('authorityList', 197, this)" @click="volumeSetStart">启动</el-button>
						<el-button plain type="primary" size="small" :disabled="!judgment('authorityList', 197, this)" @click="volumeSetStop">停止</el-button>
						<el-button plain type="primary" size="small" :disabled="!judgment('authorityList', 197, this)" @click="volumeSets">批量设置</el-button>
					</div>
				</div>
			</div>
			<div class="list-box">
				<ul class="heard-ul">
					<li class="check-box"><el-checkbox v-model="checkAll" @change="handleCheckAllChange"></el-checkbox></li>
					<li class="width-s">序号</li>
					<li class="width-sl text-left sort-box">
						名称
						<div class="sort" @click="sortData('name', $event)">
							<!-- 升序选中 -->
							<span class="iconfont icon-sort-up-full sort-full-icon up-full" data="up-full"></span>
							<!-- 升序未选中 -->
							<span class="iconfont icon-sort-up sort-icon up show" data="up"></span>
							<!-- 降序选中 -->
							<span class="iconfont icon-sort-down-full sort-full-icon down-full show" data="down-full"></span>
							<!-- 降序未选中 -->
							<span class="iconfont icon-sort-down sort-icon down " data="down"></span>
						</div>
					</li>
					<li class="address text-left">地址</li>
					<li class="width-xl text-left sort-box">
						采集器编号
						<div class="sort" @click="sortData('dno', $event)">
							<!-- 升序选中 -->
							<span class="iconfont icon-sort-up-full sort-full-icon up-full" data="up-full"></span>
							<!-- 升序未选中 -->
							<span class="iconfont icon-sort-up sort-icon up show" data="up"></span>
							<!-- 降序选中 -->
							<span class="iconfont icon-sort-down-full sort-full-icon down-full" data="down-full"></span>
							<!-- 降序未选中 -->
							<span class="iconfont icon-sort-down sort-icon down show" data="down"></span>
						</div>
					</li>
					<li class="width-xs">状态</li>
					<!-- <li class="width-xs">电量</li> -->
					<!-- <li class="alert">超标报警</li> -->
					<!-- <li class="img-li">现场图</li> -->
					<li class="set-up-li">操作</li>
				</ul>
				<div class="list-scroll">
					<vue-scroll v-show="dataList.length != 0">
						<div id="ul-content">
							<el-checkbox-group v-model="checkedPoint" @change="handleChecked">
								<ul class="content-ul" v-for="(i, index) in dataList" :key="index">
									<li class="check-box"><el-checkbox :label="i.id"></el-checkbox></li>
									<li class="width-s">{{ index + 1 }}</li>
									<li class="width-sl text-left text-overflow text-color pointer name" @click="openPointLine(i, index)" title="点击打开测点列表">{{ i.name }}</li>
									<li class="address text-left text-overflow">{{ i.address }}</li>
									<li class="width-xl text-left text-overflow text-color">{{ i.cno }}</li>
									<!-- state -->
									<li class="width-xs indent-none">
										<span
											v-show="i.dcid > 0"
											:class="i.online != 0 ? (i.state > 0 ? 'iconfont icon-xinhao green' : 'iconfont icon-xinhao red') : 'iconfont icon-xinhao grey'"
										></span>
									</li>
									<!-- <li class="width-xs indent-none dianliang">
										<div v-show="i.hasOwnProperty('powers')" class="electric">
											<div class="electric-box" v-show="i.powers != 1" :title="'当前电量：' + i.power + '%'">
												<i v-show="i.power <= 25" class="iconfont icon-dianliang red battery"></i>
												<i v-show="i.power > 25 && i.power <= 50" class="iconfont icon-dianliang1 yellow battery"></i>
												<i v-show="i.power > 50 && i.power <= 75" class="iconfont icon-dianliang3 blue-color battery"></i>
												<i v-show="i.power > 75 && i.power <= 100" class="iconfont icon-dianliang2 green battery"></i>
											</div>
											<div v-show="i.powers == 1" :title="'充电中，当前电量：' + i.power + '%'" class="electric">
												<i class="iconfont icon-dianchi_chongdian_xi charge green"></i>
											</div>
										</div>
									</li> -->
									<!-- <li class="alert indent-none text-overflow red pointer" @click="openAlarm($event, i)" title="点击进入超标报警">{{ i.alert }}</li> -->
									<!-- <li class="img-li indent-none">
										<i v-show="i.pn1 != ''" class="iconfont pointer icon-tupian imgIcon" @click="previewImage($event, i.pn1)"></i>
									</li> -->
									<li class="set-up-li indent-none">
										<i class="iconzu-box-l margin-style">
											<!-- 推送 -->
											<span
												v-show="i.dpoint == 1 && i.spush == 1"
												title="不推送"
												class="icon iconfont icon-zu1 blue-color pointer"
												@click="noPush($event, i)"
											></span>
											<!-- 不推送 -->
											<span
												v-show="i.dpoint == 1 && i.spush == 0"
												title="推送"
												class="icon iconfont icon-zu gray-color pointer"
												@click="allowPush($event, i)"
											></span>
											<!-- 禁止点击 -->
											<!-- <span
											v-show="i.dpoint == 1 && i.spush == 0"
											title=""
											class="icon iconfont icon-zu gray-color"
										></span> -->
										</i>

										<!-- <i title="测点文件" @click="selectFile($event, 1, i)" class="icon-style-s el-icon-folder-opened pointer"></i>
									<i title="报警信息" @click="openAlarm($event, 'alarm', i)" class="icon icon-style-l iconfont icon-baojing pointer"></i> -->
										<i class="iconzu-box" @click="settingShow($event, i)">
											<span class="icon-style-s el-icon-setting pointer" title="仪器设置" v-show="i.did != 0"></span>
										</i>
									</li>
								</ul>
							</el-checkbox-group>
						</div>
						<!-- 统计 -->
						<!-- <ul class="statistical-ul">
							<li class="check-box"></li>
							<li class="width-s">统计</li>
							<li class="width-sl">{{ statistical.a }}</li>
							<li class="address">{{ statistical.b }}</li>
							<li class="width-xl">{{ statistical.c }}</li>
							<li class="width-xs"></li>
							<li class="width-xs"></li>
							<li class="file">{{ statistical.e }}</li>
							<li class="alert">{{ statistical.f }}</li>
							<li class="img-li"></li>
							<li class="set-up-li"></li>
						</ul> -->
					</vue-scroll>
					<noData v-show="dataList.length == 0" />
				</div>
			</div>
		</div>
		<div class="right-box">
			<!-- 项目描述 -->
			<div class="info">
				<p class="info-title">项目描述</p>
				<div class="info-p">
					<vue-scroll>
						<p class="info-content">{{ info }}</p>
					</vue-scroll>
				</div>
			</div>
			<div class="img-content">
				<div class="right-box-title"><p class="title">测点布置图</p></div>
				<div class="img-box">
					<el-image fit="fill" style="width: 100%; height: 100%" :src="url" :preview-src-list="srcList">
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
							<span>暂无图片</span>
						</div>
					</el-image>
				</div>
			</div>
		</div>
		<!-- 现场图片预览 -->
		<el-image-viewer v-if="dialogVisible" :on-close="handleClose" :url-list="[sceneImgList]" />
		<!-- 测点查看 -->
		<el-dialog :title="'测点列表（' + pointLineName + '）'" :visible.sync="dialogPoint" custom-class="point-view" :modal-append-to-body="false" width="850px">
			<div class="table-box">
				<el-table :data="pointViewData" stripe style="width: 100%" height="100%">
					<el-table-column type="index" label="序号" width="60"></el-table-column>
					<el-table-column prop="name" label="名称" width="180"></el-table-column>
					<el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
					<el-table-column prop="sno" label="传感器编号"></el-table-column>
					<el-table-column label="现场图" align="center" width="80">
						<template slot-scope="scope">
							<div class="img"><i v-show="scope.row.pn1 != ''" class="iconfont pointer icon-tupian imgIcon" @click="previewImage($event, scope.row.pn1)"></i></div>
						</template>
					</el-table-column>
					<el-table-column label="基准点设置" align="center" width="100">
						<template slot-scope="scope">
							<div class="i-icon">
								<i class="iconzu-box">
									<!-- 设为基准点 -->
									<span
										v-show="scope.row.id == checkInfo.datum"
										title="取消基准点"
										class="icon icon-style-s iconfont icon-shoucang datumpoint1 blue-color pointer"
										@click="cancelDatumPoint($event, scope.row)"
									></span>
									<!-- 取消基准点 -->
									<span
										v-show="scope.row.id != checkInfo.datum"
										title="设为基准点"
										class="icon icon-style-s iconfont icon-shoucang datumpoint2 gray-color pointer"
										@click="setDatumPoint($event, scope.row)"
									></span>
									<!-- 禁止点击 -->
									<!-- <span v-show="!judgment('authorityList', 196, that)" title="" class="icon icon-style-s iconfont icon-shoucang datumpoint2 gray-color"></span> -->
								</i>
								<i class="iconzu-box-l margin-style">
									<!-- 推送 -->
									<span
										v-show="scope.row.dpoint == 1 && scope.row.spush == 1"
										title="不推送"
										class="icon iconfont icon-zu1 blue-color pointer"
										@click="noPush($event, scope.row)"
									></span>
									<!-- 不推送 -->
									<span
										v-show="scope.row.dpoint == 1 && scope.row.spush == 0"
										title="推送"
										class="icon iconfont icon-zu gray-color pointer"
										@click="allowPush($event, scope.row)"
									></span>
									<!-- 禁止点击 -->
									<!-- <span
									v-show="i.dpoint == 1 && i.spush == 0"
									title=""
									class="icon iconfont icon-zu gray-color"
								></span> -->
								</i>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogPoint = false">关 闭</el-button>
				<!-- <el-button type="primary" @click="dialogPoint = false">确 定</el-button> -->
			</div>
		</el-dialog>
		<!-- 仪器设置 -->
		<el-dialog :visible.sync="dialogInstrumentSettings" custom-class="instrumentSettings" :modal-append-to-body="false">
			<div slot="title">
				<span>仪器设置</span>
				（
				<i class="color-red">*</i>
				<i class="tips-text tips-size">设备在处于采集状态时，所有的设置成功设置后会先停止采集，再启动</i>
				）
			</div>
			<div class="content">
				<div class="button-box">
					<div class="info-box">
						<span>测点编号：{{ setParameter.name }}</span>
						<span>仪器编号：{{ setParameter.dno }}</span>
					</div>
					<div>
						<el-button :disabled="!judgment('authorityList', 197, this)" v-show="onLine != 0 && setState == 0" type="primary" size="mini" plain @click="startDevice">
							启动
						</el-button>
						<el-button
							:disabled="!judgment('authorityList', 197, this) || (onLine == 0 || setState != 0)"
							v-show="onLine == 0 || setState != 0"
							type="info"
							size="mini"
							plain
						>
							启动
						</el-button>
						<el-button :disabled="!judgment('authorityList', 197, this)" v-show="onLine != 0 && setState != 0" type="primary" size="mini" plain @click="stopDevice">
							停止
						</el-button>
						<el-button
							:disabled="!judgment('authorityList', 197, this) || (onLine == 0 || setState != 0)"
							v-show="onLine == 0 || setState == 0"
							type="info"
							size="mini"
							plain
						>
							停止
						</el-button>
						<el-button :disabled="!judgment('authorityList', 197, this)" v-show="onLine != 0" type="primary" size="mini" plain @click="restartDevice">重启</el-button>
						<el-button :disabled="!judgment('authorityList', 197, this) || onLine == 0" v-show="onLine == 0" type="info" size="mini" plain>重启</el-button>
						<el-button :disabled="!judgment('authorityList', 197, this)" v-show="onLine != 0" type="primary" size="mini" plain @click="empty">清空内存</el-button>
						<el-button :disabled="!judgment('authorityList', 197, this) || onLine == 0" v-show="onLine == 0" type="info" size="mini" plain>清空内存</el-button>
					</div>
				</div>
				<div class="main-box">
					<el-tabs v-model="setActiveName" tab-position="left" @tab-click="setHandleClick">
						<el-tab-pane label="记录参数" name="1">
							<div class="record tab-pane-box">
								<div class="cell-box">
									<div class="cell">
										<p class="cell-title letter-spacing">采集方式:</p>
										&nbsp;
										<span>{{ acquisitionMethods[record.status] }}</span>
									</div>
									<div class="cell">
										<p class="cell-title">触发电平</p>
										<el-input v-model="record.tvoltage" placeholder="请输入"></el-input>
										<p class="unit">cm/s</p>
										<span class="tips">设置范围:0.001~35.5</span>
									</div>
									<div class="cell">
										<p class="cell-title">存储长度</p>
										<el-input v-model="record.slength" placeholder="请输入"></el-input>
										<p class="unit">秒</p>
										<span class="tips">设置范围:1~99</span>
									</div>
									<div class="cell">
										<p class="cell-title">负延时</p>
										<el-input v-model="record.ndelay" placeholder="请输入"></el-input>
										<p class="unit">秒</p>
										<span class="tips">设置范围:0~0.255</span>
									</div>
									<div class="cell">
										<p class="cell-title">采集间隔</p>
										<el-input v-model="record.intime" placeholder="请输入"></el-input>
										<p class="unit">秒</p>
									</div>
									<div class="cell">
										<p class="cell-title">采样率</p>
										<el-select v-model="record.srate" placeholder="请选择">
											<el-option v-for="(item, index) in samplingRate" :key="item" :label="item" :value="index"></el-option>
										</el-select>
										<p class="unit">HZ</p>
									</div>
								</div>
								<div class="bottomBox">
									<el-button plain :disabled="!judgment('authorityList', 197, that)" type="primary" size="mini" @click="setrecord">设置</el-button>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="工程信息" name="2">
							<div class="engineering tab-pane-box">
								<div class="cell-box">
									<div class="cell tips-cell">
										<p class="tips-text">
											<i class="color-red">*</i>
											<!-- 本页面所有设置受设备的限制，超出指定长度部分的内容不会设置到设备，字符长度参考输入框后面的提示( -->
											中文占两个字符长度，英文、数字占一个字符长度。测点编号只能有字母、数字、‘+’或‘-’
											<!-- ) -->
										</p>
									</div>
									<div class="cell">
										<p class="cell-title">用户名称</p>
										<!-- <el-input v-model="engineering.cname" placeholder="请输入"></el-input> -->
										<p class="conent-text text-overflow" :title="engineering.cname">{{ engineering.cname }}</p>
										<span class="tips">
											<i class="color-red">*</i>
											字符长度：32
										</span>
									</div>
									<div class="cell">
										<p class="cell-title">工程项目</p>
										<!-- <el-input v-model="engineering.pjname" placeholder="请输入"></el-input> -->
										<p class="conent-text text-overflow" :title="engineering.pjname">{{ engineering.pjname }}</p>
										<span class="tips">
											<i class="color-red">*</i>
											字符长度：27
										</span>
									</div>
									<div class="cell">
										<p class="cell-title">操作员</p>
										<el-input v-model="engineering.operator" :max="6" placeholder="请输入"></el-input>
										<span class="tips">
											<i class="color-red">*</i>
											字符长度：12
										</span>
									</div>
									<div class="cell">
										<p class="cell-title">测点编号</p>
										<el-input v-model="engineering.pname" placeholder="请输入"></el-input>
										<span class="tips">
											<i class="color-red">*</i>
											字符长度：9
										</span>
									</div>
									<div class="cell">
										<p class="cell-title">校验日期</p>
										<el-date-picker v-model="engineering.oterm" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
									</div>
									<div class="cell">
										<p class="cell-title">名称位置</p>
										<el-input v-model="engineering.paddress" placeholder="请输入"></el-input>
									</div>
									<div class="cell">
										<p class="cell-title">通讯号码</p>
										<el-input v-model="engineering.phone" placeholder="请输入"></el-input>
									</div>
									<div class="cell">
										<p class="cell-title">测点坐标</p>
										<div class="cell-child-box">
											<div class="cell-child">
												<p class="cell-child-title">经度</p>
												<el-input v-model="engineering.lng" placeholder="请输入"></el-input>
											</div>
											<div class="cell-child">
												<p class="cell-child-title">纬度</p>
												<el-input v-model="engineering.lat" placeholder="请输入"></el-input>
											</div>
											<div class="cell-child">
												<p class="cell-child-title">海拔</p>
												<el-input v-model="engineering.high" placeholder="请输入"></el-input>
											</div>
										</div>
									</div>
								</div>
								<div class="bottomBox">
									<el-button plain :disabled="!judgment('authorityList', 197, that)" type="primary" size="mini" @click="setengineering">设置</el-button>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="报警设置" name="3">
							<div class="police tab-pane-box">
								<div class="cell-box">
									<div class="cell">
										<p class="cell-title">报警值</p>
										<el-input v-model="police.alert" placeholder="请输入"></el-input>
										<p class="unit">cm/s</p>
									</div>
									<div class="cell">
										<p class="cell-title">报警方式</p>
										<div class="cell-child-box">
											<div class="cell-child">
												<el-checkbox :true-label="1" :false-label="0" v-model="police.pstate"></el-checkbox>
												<p>短信报警</p>
											</div>
											<div class="cell-child margin-bottom-s">
												<p>
													电话号码
													<span class="tips-info">（多个电话中间用逗号分隔，最多每行输入3个）</span>
												</p>
											</div>
											<div class="cell-child margin-bottom-s">
												<el-input type="text" v-model="police.phoneone" maxlength="40" show-word-limit placeholder="请输入"></el-input>
											</div>
											<div class="cell-child margin-bottom-s">
												<el-input type="text" v-model="police.phonetwo" maxlength="40" show-word-limit placeholder="请输入"></el-input>
											</div>
										</div>
										<!-- <div class="cell-child-box">
											<div class="cell-child">
												<el-checkbox :true-label="1" :false-label="0" v-model="police.estate"></el-checkbox>
												<p>电邮报警</p>
											</div>
											<div class="cell-child margin-bottom-s"><p>邮件地址</p></div>
											<div class="cell-child margin-bottom-s"><el-input type="email" v-model="police.emailone" placeholder="请输入"></el-input></div>
											<div class="cell-child margin-bottom-s"><el-input type="email" v-model="police.emailtwo" placeholder="请输入"></el-input></div>
										</div> -->
									</div>
								</div>
								<div class="bottomBox">
									<el-button plain :disabled="!judgment('authorityList', 197, that)" type="primary" size="mini" @click="setpolice">设置</el-button>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="定时开关" name="4">
							<div class="timing tab-pane-box">
								<div class="cell-box">
									<div class="cell">
										<p class="tips-text">
											<i class="color-red">*</i>
											当前时段设置后定时开关功能视为启用！
										</p>
									</div>
									<div class="cell">
										<p class="cell-title">时段设置</p>
										<div class="cell-child-box">
											<div class="cell-child">
												<el-input type="number" min="0" max="23" v-model="timing.timeoneH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="timing.timeoneM" placeholder="分"></el-input>
												&nbsp;~&nbsp;
												<el-input type="number" min="0" max="23" v-model="timing.timeonesH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="timing.timeonesM" placeholder="分"></el-input>
											</div>
											<div class="cell-child">
												<el-input type="number" min="0" max="23" v-model="timing.timetwoH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="timing.timetwoM" placeholder="分"></el-input>
												&nbsp;~&nbsp;
												<el-input type="number" min="0" max="23" v-model="timing.timetwosH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="timing.timetwosM" placeholder="分"></el-input>
											</div>
											<div class="cell-child">
												<el-input type="number" min="0" max="23" v-model="timing.timethreeH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="timing.timethreeM" placeholder="分"></el-input>
												&nbsp;~&nbsp;
												<el-input type="number" min="0" max="23" v-model="timing.timethreesH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="timing.timethreesM" placeholder="分"></el-input>
											</div>
										</div>
									</div>
								</div>
								<div class="bottomBox">
									<el-button plain :disabled="!judgment('authorityList', 197, that)" type="primary" size="mini" @click="settiming">设置</el-button>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane v-if="judgment('authorityList', 110, that)" label="仪器配置" name="5">
							<div class="theInstrument tab-pane-box">
								<div class="cell-box">
									<div class="cell">
										<p class="cell-title">传感器编号</p>
										<el-input v-model="instrument.sno" placeholder="请输入"></el-input>
									</div>
									<div class="cell">
										<p class="cell-title">传感器类型</p>
										<el-select v-model="instrument.stype" placeholder="请选择">
											<el-option v-for="item in sensorType" :key="item.id" :label="item.name" :value="item.id"></el-option>
										</el-select>
									</div>
									<div class="cell">
										<p class="cell-title">传感器参数</p>
										<div class="cell-child-box">
											<div class="cell-child">
												<p class="name"></p>
												<p class="coefficient">系数</p>
											</div>
											<div class="cell-child">
												<p class="name">通道X</p>
												<p class="coefficient"><el-input v-model="instrument.xratio"></el-input></p>
											</div>
											<div class="cell-child">
												<p class="name">通道Y</p>
												<p class="coefficient"><el-input v-model="instrument.yratio"></el-input></p>
											</div>
											<div class="cell-child">
												<p class="name">通道Z</p>
												<p class="border-none coefficient"><el-input v-model="instrument.zratio"></el-input></p>
											</div>
										</div>
									</div>
								</div>
								<div class="bottomBox">
									<el-button plain :disabled="!judgment('authorityList', 197, that)" type="primary" size="mini" @click="setinstrument">设置</el-button>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane v-if="judgment('authorityList', 110, that)" label="网络参数" name="6">
							<div class="network tab-pane-box">
								<div class="cell-box">
									<div class="cell">
										<p class="cell-title">服务器地址</p>
										<el-input v-model="network.sip" placeholder="请输入"></el-input>
									</div>
									<div class="cell">
										<p class="cell-title">服务器端口</p>
										<el-input v-model="network.sport" placeholder="请输入"></el-input>
									</div>
									<div class="cell">
										<p class="cell-title">本机IP</p>
										<el-input v-model="network.dip" placeholder="请输入"></el-input>
									</div>
									<div class="cell">
										<!-- <p class="cell-title">服务器IP</p>
										<el-input v-model="network.sip" placeholder="请输入"></el-input> -->
									</div>
									<div class="cell">
										<p class="cell-title letter-spacing">网关:</p>
										<span>{{ network.gateway }}</span>
									</div>
									<div class="cell">
										<p class="cell-title letter-spacing">掩码:</p>
										<span>{{ network.mask }}</span>
									</div>
									<div class="cell">
										<p class="cell-title letter-spacing">硬件版本:</p>
										<p class="cell-content">{{ network.hversion }}</p>
									</div>
									<div class="cell">
										<p class="cell-title letter-spacing">软件版本:</p>
										<p class="cell-content">{{ network.sversion }}</p>
									</div>
								</div>
								<div class="bottomBox">
									<el-button plain :disabled="!judgment('authorityList', 197, that)" type="primary" size="mini" @click="setnetwork">设置</el-button>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</el-dialog>
		<!-- 批量设置 -->
		<el-dialog title="批量设置" :visible.sync="dialogVolumeSet" custom-class="volumeSet" :modal-append-to-body="false">
			<div class="content">
				<div class="main-box">
					<el-tabs v-model="batchActiveName" tab-position="left" @tab-click="volumeSetHandleClick">
						<el-tab-pane label="记录参数" name="1">
							<div class="record tab-pane-box">
								<div class="cell-box">
									<!-- <div class="cell">
										<p class="cell-title letter-spacing">采集方式:</p>&nbsp;
										<span>{{acquisitionMethods[batchRecord.status]}}</span>
									</div> -->
									<div class="cell">
										<p class="cell-title">触发电平</p>
										<el-input v-model="batchRecord.tvoltage" placeholder="请输入"></el-input>
										<p class="unit">cm/s</p>
										<span class="tips">设置范围:0.001~35.5</span>
									</div>
									<div class="cell">
										<p class="cell-title">存储长度</p>
										<el-input v-model="batchRecord.slength" placeholder="请输入"></el-input>
										<p class="unit">秒</p>
										<span class="tips">设置范围:1~99</span>
									</div>
								</div>
								<div class="bottomBox"><el-button plain type="primary" size="mini" @click="volumeSetrecord">设置</el-button></div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="报警设置" name="2">
							<div class="police tab-pane-box">
								<div class="cell-box">
									<div class="cell">
										<p class="cell-title">报警幅值</p>
										<el-input v-model="batchPolice.alert" placeholder="请输入"></el-input>
										<p class="unit">cm/s</p>
									</div>
									<div class="cell">
										<p class="cell-title">报警方式</p>
										<div class="cell-child-box">
											<div class="cell-child">
												<el-checkbox :true-label="1" :false-label="0" v-model="batchPolice.pstate"></el-checkbox>
												<p>短信报警</p>
											</div>
											<div class="cell-child margin-bottom-s"><p>电话号码</p></div>
											<div class="cell-child margin-bottom-s"><el-input type="number" v-model="batchPolice.phoneone" placeholder="请输入"></el-input></div>
											<div class="cell-child margin-bottom-s"><el-input type="number" v-model="batchPolice.phonetwo" placeholder="请输入"></el-input></div>
										</div>
										<!-- <div class="cell-child-box">
											<div class="cell-child">
												<el-checkbox :true-label="1" :false-label="0" v-model="batchPolice.estate"></el-checkbox>
												<p>电邮报警</p>
											</div>
											<div class="cell-child margin-bottom-s"><p>邮件地址</p></div>
											<div class="cell-child margin-bottom-s"><el-input type="email" v-model="batchPolice.emailone" placeholder="请输入"></el-input></div>
											<div class="cell-child margin-bottom-s"><el-input type="email" v-model="batchPolice.emailtwo" placeholder="请输入"></el-input></div>
										</div> -->
									</div>
								</div>
								<div class="bottomBox"><el-button plain type="primary" size="mini" @click="volumeSetpolice">设置</el-button></div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="定时开关" name="3">
							<div class="timing tab-pane-box">
								<div class="cell-box">
									<div class="cell">
										<p class="tips-text">
											<i class="color-red">*</i>
											当前时段设置后定时开关功能视为启用！
										</p>
									</div>
									<div class="cell">
										<p class="cell-title">时段设置</p>
										<div class="cell-child-box">
											<div class="cell-child">
												<el-input type="number" min="0" max="23" v-model="batchTiming.timeoneH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="batchTiming.timeoneM" placeholder="分"></el-input>
												&nbsp;~&nbsp;
												<el-input type="number" min="0" max="23" v-model="batchTiming.timeonesH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="batchTiming.timeonesM" placeholder="分"></el-input>
											</div>
											<div class="cell-child">
												<el-input type="number" min="0" max="23" v-model="batchTiming.timetwoH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="batchTiming.timetwoM" placeholder="分"></el-input>
												&nbsp;~&nbsp;
												<el-input type="number" min="0" max="23" v-model="batchTiming.timetwosH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="batchTiming.timetwosM" placeholder="分"></el-input>
											</div>
											<div class="cell-child">
												<el-input type="number" min="0" max="23" v-model="batchTiming.timethreeH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="batchTiming.timethreeM" placeholder="分"></el-input>
												&nbsp;~&nbsp;
												<el-input type="number" min="0" max="23" v-model="batchTiming.timethreesH" placeholder="时"></el-input>
												&nbsp;:&nbsp;
												<el-input type="number" min="0" max="59" v-model="batchTiming.timethreesM" placeholder="分"></el-input>
											</div>
										</div>
									</div>
								</div>
								<div class="bottomBox"><el-button plain type="primary" size="mini" @click="volumeSettiming">设置</el-button></div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
	name: '',
	data() {
		return {
			state: null, //选择的状态
			stateOptions: [
				{
					value: 2,
					name: '全部'
				},
				{
					value: 1,
					name: '在线'
				},
				{
					value: 0,
					name: '离线'
				},
				{
					value: 3,
					name: '启用'
				},
				{
					value: 4,
					name: '停用'
				}
			], //状态选择数据列表
			list: [], //列表数据
			sortStatue: 'down-full', // 列表排序状态
			sortName: 'name', // 列表排序字段
			statistical: {
				a: '', //测点总数
				b: '', //设备总数
				c: '', //文件总数
				e: '', //报警总数
				f: '' //现场图总数
			}, // 统计数据
			pointLineName: '', //点击的测点线名称
			pointViewData: [], //测点弹窗列表数据
			dialogPoint: false, // 测点弹窗是否打开
			checkInfo: {
				id: null, //测点线id
				index: null, //测点线数据index
				datum: null //基准点测点id
			}, //点击打开测点列表的测点线信息
			checkAll: false, //测点线列表是否全选
			checkedPoint: [], //测点线列表选择数据
			url: '', //显示图片地址
			info: '', // 项目信息
			srcList: [], //预览图片地址
			content: '', //工程场景内容
			sceneImgList: '', // 现场图片
			dialogVisible: false, //现场图片弹窗是否显示
			dialogVolumeSet: false, //批量设置弹窗是否显示
			batchActiveName: '1', //仪器设置菜单选中
			batchInfo: {}, // 批量设置信息
			dialogInstrumentSettings: false, //仪器设置弹窗是否显示
			setActiveName: '1', //仪器设置菜单选中
			setParameter: {}, //仪器设置选中列表参数
			samplingRate: [], // 采样率
			interval: [], // 采集间隔
			sensorType: [], // 传感器类型
			acquisitionMethods: ['未采集', '抽样采集', '电平采集', '电平+抽样采集'], // 采集方式

			// 批量设置参数
			batchRecord: {
				tvoltage: null, //触发电平
				slength: null // 存储长度
			}, // 记录参数
			batchPolice: {
				alert: null, // 报警幅值
				pstate: 0, //短信报警是否勾选
				estate: 0, //电邮报警是否勾选
				phoneone: '', // 电话号码1 aphone
				phonetwo: '', // 电话号码2 aphone
				emailone: '', // 邮箱地址1 aemail
				emailtwo: '' // 邮箱地址2 aemail
			}, // 报警设置
			batchTiming: {
				timeoneH: '',
				timeoneM: '',
				timeonesH: '',
				timeonesM: '', // 时间1 timer1
				timetwoH: '',
				timetwoM: '',
				timetwosH: '',
				timetwosM: '', // 时间2 timer2
				timethreeH: '',
				timethreeM: '',
				timethreesH: '',
				timethreesM: '' // 时间3 timer3
			}, // 定时开关
			batchTimingSet: false, // 是否允许定时开关进行设置
			// 批量设置参数结束

			//仪器设置数据
			setState: 0, //仪器启动和停止按钮状态
			onLine: 0, // 仪器是否离线
			record: {
				status: null, // 采集方式
				tvoltage: null, //触发电平
				slength: null, // 存储长度
				ndelay: null, // 负延时
				srate: null, // 采样率
				intime: null //采集间隔
			}, // 记录参数
			engineering: {
				cname: '', // 用户名称
				pjname: '', // 工程项目
				operator: '', // 操作员
				pname: '', // 测点编号
				oterm: '', // 有效期限
				paddress: '', // 名称位置
				phone: '', // 通讯号码
				lng: null, // 经度
				lat: null, // 纬度
				high: null // 海拔
			}, // 工程信息
			police: {
				alert: null, // 报警值
				pstate: 0, //短信报警是否勾选
				estate: 0, //电邮报警是否勾选
				phoneone: '', // 电话号码1 aphone
				phonetwo: '', // 电话号码2 aphone
				emailone: '', // 邮箱地址1 aemail
				emailtwo: '' // 邮箱地址2 aemail
			}, // 报警设置
			timing: {
				timeoneH: '',
				timeoneM: '',
				timeonesH: '',
				timeonesM: '', // 时间1 timer1
				timetwoH: '',
				timetwoM: '',
				timetwosH: '',
				timetwosM: '', // 时间2 timer2
				timethreeH: '',
				timethreeM: '',
				timethreesH: '',
				timethreesM: '' // 时间3 timer3
			}, // 定时开关
			timingSet: false, // 是否允许定时开关进行设置
			instrument: {
				sno: '', // 传感器编号
				stype: null, // 传感器类型
				xratio: null,
				yratio: null,
				zratio: null // 传感器参数
			}, // 仪器配置
			network: {
				dip: '', // 本机IP
				sip: '', // 服务器IP
				sport: '', // 服务器端口
				gateway: '', // 网关
				mask: '', // 掩码
				hversion: '', // 硬件版本
				sversion: '' // 软件版本
			}, // 网络参数
			//仪器设置数据结束
			//权限数组中id对应的功能 name:设备控制(所有设备控制按钮)/删除数据/报告管理（数据列表保存报告、报告列表里面删除报告）/报警操作/设置基准点/推送/任务功能（创建任务（报警列表中也有）、完成任务、结束任务）
			authorityList: [], //权限数组 id:14/4/6/8/12/13/31
			that: this, //当前页面this
			parameter: {} // 请求所需参数
		};
	},
	created() {
		var query = this.$route.query;
		this.parameter = query;
		if (query.id) {
			if (query.img != '') {
				this.url = this.constData.imgUrl + 'res/static/ppname/1' + query.img;
			}
			this.srcList = [this.url];
			this.content = query.content;
			if (query.hasOwnProperty('status')) {
				this.state = Number(query.status);
			}
			this.getList(query);
			this.getInfo(query.id);
		} else {
			this.toRouter('index');
		}
		this.getAuthorityList();
		// 采集间隔
		this.interval = this.constData.interval;
		// 采样率
		this.samplingRate = this.constData.samplingRate;
		// 传感器类型
		this.sensorType = this.constData.sensorType;
	},
	mounted() {},
	computed: {
		dataList: function() {
			var arr = this.list.filter(item => {
				if (this.state == null) {
					return item;
				}
				if (this.state == 2) {
					return item;
				} else {
					if (this.state < 2) {
						if (this.state == item.online) {
							return item;
						}
					} else {
						if (this.state == 3) {
							return item.did != 0;
						} else if (this.state == 4) {
							return item.did == 0;
						}
					}
				}
			});
			var newArr = arr.sort((a, b) => {
				if (this.sortStatue == 'down-full') {
					return a[this.sortName].localeCompare(b[this.sortName]);
				} else if (this.sortStatue == 'up-full') {
					return b[this.sortName].localeCompare(a[this.sortName]);
				}
			});
			return newArr;
		}
	},
	methods: {
		// 页面权限数据获取
		getAuthorityList() {
			this.authorityList = [];
			this.axios.get('web2/dfmt/ptlin/odnr/gth').then(res => {
				if (res.status) {
					this.authorityList = res.data;
					// // console.log(this.authorityList);
				}
			});
		},
		// 列表数据获取
		getList(query) {
			var data = {
				id: query.id
			};
			this.axios.post('/web2/dfmt/ptlin/odnr/gplist', data).then(res => {
				this.list = [];
				// for (let key in this.statistical) {
				// 	this.statistical[key] = '';
				// }
				if (res.status) {
					this.list = res.data;
					// for (let key in this.statistical) {
					// 	this.statistical[key] = res.data[key];
					// }
					// $(() => {
					// 	var height = $('.list-scroll').height();
					// 	var len = this.dataList.length;
					// 	// // console.log(height);
					// 	if (len * 40 + 38 >= height) {
					// 		$('#ul-content').css({
					// 			paddingBottom: '35px'
					// 		});
					// 		$('.statistical-ul').css({
					// 			position: 'fixed',
					// 			left: '0',
					// 			top: height - 37 + 'px',
					// 			zIndex: 10
					// 		});
					// 	} else {
					// 		$('#ul-content').css({
					// 			paddingBottom: '0'
					// 		});
					// 		$('.statistical-ul').removeAttr('style');
					// 	}
					// });
				}
			});
		},
		// 列表数据排序
		sortData(val, e) {
			this.sortName = val;
			let obj = $('.sort').not($(e.currentTarget));
			// // console.log(obj);
			obj.find('.sort-full-icon').hide();
			obj.find('.sort-icon').show();
			let up = $(e.currentTarget).find('.up');
			let upFull = $(e.currentTarget).find('.up-full');
			let down = $(e.currentTarget).find('.down');
			let downFull = $(e.currentTarget).find('.down-full');
			if (upFull.is(':visible')) {
				upFull.hide();
				up.show();
				downFull.show();
				down.hide();
				this.sortStatue = downFull.attr('data');
			} else {
				upFull.show();
				up.hide();
				downFull.hide();
				down.show();
				this.sortStatue = upFull.attr('data');
			}
		},
		// 项目描述信息获取
		getInfo(id) {
			this.axios.post('web2/dfmt/ptlin/odnr/gpmk', { id: id }).then(res => {
				if (res.status) {
					this.info = res.data;
				}
			});
		},
		// 预览现场图片
		previewImage(e = null, url) {
			if (e != null) {
				this.theSelected(e);
			}
			this.sceneImgList = this.constData.imgUrl + 'res/static/ptpc/1' + url;
			this.dialogVisible = true;
		},
		// 关闭现场弹窗预览
		handleClose() {
			this.dialogVisible = false;
		},
		// 打开测点线查看测点
		openPointLine(row, index) {
			// // console.log(row);
			this.checkInfo.index = index;
			this.checkInfo.datum = row.datum;
			this.checkInfo.id = row.id;
			if (row.hasOwnProperty('points') && row.points.length != 0) {
				this.pointLineName = row.name;
				this.pointViewData = row.points;
				this.dialogPoint = true;
			} else {
				this.$message({
					showClose: true,
					message: '该测点线暂无测点',
					type: 'warning'
				});
			}
		},
		//测点列表直接全选
		handleCheckAllChange(val) {
			if (val) {
				this.dataList.forEach(item => {
					this.checkedPoint.push(item.id);
				});
			} else {
				this.checkedPoint = [];
			}
		},
		//测点列表选择
		handleChecked(value) {
			let len = value.length;
			this.checkAll = len === this.dataList.length;
		},
		// 批量设置启动
		volumeSetStart() {
			if (this.checkedPoint.length == 0) {
				this.$message({
					showClose: true,
					message: '请至少选择一个测点！',
					type: 'error'
				});
				return;
			}
			var idArr = [];
			this.dataList.forEach(item => {
				if (this.checkedPoint.indexOf(item.id) != -1) {
					if (item.did != 0) {
						idArr.push(item.did);
					}
				}
			});
			if (idArr.length == 0) {
				this.$message({
					showClose: true,
					message: '所选测点都未绑定设备',
					type: 'warning'
				});
				return;
			}
			var data = {
				ids: idArr.toString(),
				type: 1
			};
			// web2/points/pmc/real
			this.axios.post('monitor/control/breal', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '成功发送启动指令',
						type: 'success'
					});
					this.checkAll = false;
					this.checkedPoint = [];
					setTimeout(() => {
						this.getList(this.parameter);
					}, 3000);
				}
			});
		},
		// 批量设置停止
		volumeSetStop() {
			if (this.checkedPoint.length == 0) {
				this.$message({
					showClose: true,
					message: '请至少选择一个测点！',
					type: 'error'
				});
				return;
			}
			var idArr = [];
			this.dataList.forEach(item => {
				if (this.checkedPoint.indexOf(item.id) != -1) {
					if (item.did != 0) {
						idArr.push(item.did);
					}
				}
			});
			if (idArr.length == 0) {
				this.$message({
					showClose: true,
					message: '所选测点都未绑定设备',
					type: 'warning'
				});
				return;
			}
			var data = {
				ids: idArr.toString(),
				type: 2
			};
			// web2/points/pmc/real
			this.axios.post('monitor/control/breal', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '成功发送停止指令',
						type: 'success'
					});
					this.checkAll = false;
					this.checkedPoint = [];
					setTimeout(() => {
						this.getList(this.parameter);
					}, 3000);
				}
			});
		},
		// 打开批量设置弹窗
		volumeSets() {
			if (this.checkedPoint.length == 0) {
				this.$message({
					showClose: true,
					message: '请至少选择一个测点！',
					type: 'error'
				});
				return;
			}
			this.dialogVolumeSet = true;
		},
		// 设为基准点
		setDatumPoint(e = null, val) {
			if (e != null) {
				this.theSelected(e);
			}
			var data = {
				id: this.checkInfo.id,
				datum: val.id
			};
			this.axios.post('web2/dfmt/ptlin/pmd/datum', data).then(res => {
				if (res.status) {
					this.$set(this.list[this.checkInfo.index], 'datum', val.id);
					this.$set(this.checkInfo, 'datum', val.id);
					this.$message({
						showClose: true,
						message: res.message,
						type: 'success'
					});
				}
			});
		},
		// 取消基准点
		cancelDatumPoint(e = null, val) {
			if (e != null) {
				this.theSelected(e);
			}
			var data = {
				id: val.id, //测点线id
				datum: 0 //测点id
			};
			this.axios.post('web2/dfmt/ptlin/pmd/datum', data).then(res => {
				if (res.status) {
					this.$set(this.list[this.checkInfo.index], 'datum', 0);
					this.$set(this.checkInfo, 'datum', 0);
					this.$message({
						showClose: true,
						message: res.message,
						type: 'success'
					});
				}
			});
		},
		// 允许推送
		allowPush(e = null, val) {
			if (e != null) {
				this.theSelected(e);
			}
			var data = {
				pointId: val.id,
				type: 1
			};
			this.axios.post('monitor/mpp', data).then(res => {
				if (res.status) {
					val.spush = 1;
					this.$message({
						showClose: true,
						message: res.message,
						type: 'success'
					});
				}
			});
		},
		// 不推送
		noPush(e = null, val) {
			if (e != null) {
				this.theSelected(e);
			}
			var data = {
				pointId: val.id,
				type: 0
			};
			this.axios.post('monitor/mpp', data).then(res => {
				if (res.status) {
					val.spush = 0;
					this.$message({
						showClose: true,
						message: res.message,
						type: 'success'
					});
				}
			});
		},
		// 跳转至报警信息页面
		openAlarm(e, data) {
			var val = {
				id: this.$route.query.id,
				name: this.$route.query.name,
				img: this.$route.query.img,
				pointId: data.id
			};

			this.toRouter('alarm', val, 'query');
			e.stopPropagation();
		},
		// 跳转至数据页面
		selectFile(e, data) {
			var val = {
				id: this.$route.query.id,
				name: this.$route.query.name,
				img: this.$route.query.img,
				pointId: data.id
			};
			this.toRouter('dataManagement', val, 'query');
			e.stopPropagation();
		},
		// 打开仪器设置弹窗
		settingShow(e = null, data) {
			if (e != null) {
				this.theSelected(e);
			}
			this.setParameter = data;
			this.getSetInfo();
			this.dialogInstrumentSettings = true;
			e.stopPropagation();
		},
		// 批量设置页面菜单选择
		volumeSetHandleClick(tab, event) {
			// // console.log(tab, event);
			if (tab.name == '3') {
				this.$nextTick(() => {
					// 限制批量控制定时开关输入框输入范围
					$('.volumeSet .timing input').blur(e => {
						var obj = $(e.currentTarget);
						var val = obj.val();
						if (val == '') {
							return;
						}
						var max = Number(obj.attr('max'));
						var min = Number(obj.attr('min'));
						if (val > max || val < min) {
							if (max == 23) {
								this.$message({
									showClose: true,
									message: '请输入正确的小时值（0-23）！',
									type: 'error'
								});
							} else if (max == 59) {
								this.$message({
									showClose: true,
									message: '请输入正确的分钟值（0-59）！',
									type: 'error'
								});
							}
							this.batchTimingSet = false;
						} else {
							this.batchTimingSet = true;
						}
					});
				});
			}
		},
		// 刷新设备列表(延迟刷新，避免状态不生效)
		refreshlist() {
			setTimeout(() => {
				this.getList(this.parameter);
			}, 3000);
		},
		//  批量设置记录参数
		volumeSetrecord() {
			var idArr = [];
			this.dataList.forEach(item => {
				if (this.checkedPoint.indexOf(item.id) != -1) {
					if (item.did != 0) {
						idArr.push(item.did);
					}
				}
			});
			if (idArr.length == 0) {
				this.$message({
					showClose: true,
					message: '所选测点都未绑定设备',
					type: 'warning'
				});
				return;
			}
			var data = {
				ids: idArr.toString()
			};
			for (let key in this.batchRecord) {
				data[key] = this.batchRecord[key];
			}
			this.axios.post('monitor/control/mbr', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设置成功',
						type: 'success'
					});
				}
			});
		},
		//  批量设置报警设置
		volumeSetpolice() {
			var idArr = [];
			this.dataList.forEach(item => {
				if (this.checkedPoint.indexOf(item.id) != -1) {
					if (item.did != 0) {
						idArr.push(item.did);
					}
				}
			});
			if (idArr.length == 0) {
				this.$message({
					showClose: true,
					message: '所选测点都未绑定设备',
					type: 'warning'
				});
				return;
			}
			var data = {
				ids: idArr.toString()
			};
			for (let key in this.batchPolice) {
				if (key.indexOf('phone') == -1 && key.indexOf('email') == -1) {
					data[key] = this.batchPolice[key];
				} else {
					data.aphone = this.batchPolice.phoneone + ',' + this.batchPolice.phonetwo;
					data.aemail = this.batchPolice.emailone + ',' + this.batchPolice.emailtwo;
				}
			}
			this.axios.post('monitor/control/mba', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设置成功',
						type: 'success'
					});
				}
			});
		},
		//  批量设置定时开关
		volumeSettiming() {
			setTimeout(() => {
				if (!this.batchTimingSet) {
					return;
				}
				var idArr = [];
				this.dataList.forEach(item => {
					if (this.checkedPoint.indexOf(item.id) != -1) {
						if (item.did != 0) {
							idArr.push(item.did);
						}
					}
				});
				if (idArr.length == 0) {
					this.$message({
						showClose: true,
						message: '所选测点都未绑定设备',
						type: 'warning'
					});
					return;
				}
				var data = {
					ids: idArr.toString(),
					timer1: '',
					timer2: '',
					timer3: ''
				};
				var num1 = 0,
					num2 = 0,
					num3 = 0;
				for (let key in this.batchTiming) {
					if (this.batchTiming[key] != '') {
						if (key.indexOf('one') != -1) {
							data.timer1 = data.timer1 + this.batchTiming[key] + '-';
							if (this.batchTiming[key] != '') {
								num1 += 1;
							}
						}
						if (key.indexOf('two') != -1) {
							data.timer2 = data.timer2 + this.batchTiming[key] + '-';
							if (this.batchTiming[key] != '') {
								num2 += 1;
							}
						}
						if (key.indexOf('three') != -1) {
							data.timer3 = data.timer3 + this.batchTiming[key] + '-';
							if (this.batchTiming[key] != '') {
								num3 += 1;
							}
						}
					}
				}

				if (num1 != 0) {
					if (num1 < 4) {
						this.$message({
							showClose: true,
							message: '请将第一行时间填写完整',
							type: 'error'
						});
						return;
					}
				} else if (num2 != 0) {
					if (num2 < 4) {
						this.$message({
							showClose: true,
							message: '请将第二行时间填写完整',
							type: 'error'
						});
						return;
					}
				} else if (num3 != 0) {
					if (num3 < 4) {
						this.$message({
							showClose: true,
							message: '请将第三行时间填写完整',
							type: 'error'
						});
						return;
					}
				} else {
					this.$message({
						showClose: true,
						message: '请至少填写一行完整的时间',
						type: 'error'
					});
					return;
				}
				for (let key in data) {
					if (key.indexOf('timer') != -1) {
						data[key] = data[key].substr(0, data[key].length - 1);
					}
				}

				this.axios.post('monitor/control/mbt', data).then(res => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '设置成功',
							type: 'success'
						});
					}
				});
			}, 100);
		},

		// 仪器设置页面菜单选择
		setHandleClick(tab, event) {
			// // console.log(tab, event);
			if (tab.name == '4') {
				this.$nextTick(() => {
					// console.log($('.instrumentSettings .timing input'));
					// 限制仪器设置定时开关输入框输入范围
					$('.instrumentSettings .timing input').blur(e => {
						var obj = $(e.currentTarget);
						var val = obj.val();
						if (val == '') {
							return;
						}
						var max = Number(obj.attr('max'));
						var min = Number(obj.attr('min'));
						if (val > max || val < min) {
							if (max == 23) {
								this.$message({
									showClose: true,
									message: '请输入正确的小时值（0-23）！',
									type: 'error'
								});
							} else if (max == 59) {
								this.$message({
									showClose: true,
									message: '请输入正确的分钟值（0-59）！',
									type: 'error'
								});
							}
							this.timingSet = false;
						} else {
							this.timingSet = true;
						}
					});
				});
			}
		},
		// 仪器设置
		// 获取仪器设置全部参数信息 gdpi
		getSetInfo() {
			var data = {
				did: this.setParameter.did
			};
			this.axios.post('web2/points/odnr/gdinfo', data).then(res => {
				this.clearData('timing');
				if (res.status) {
					var value = res.data;
					this.setState = value.status;
					this.onLine = value.online;
					if (value.aphone != '') {
						var phoneArr = value.aphone.split(',');
						this.police.phoneone = phoneArr[0];
						this.police.phonetwo = phoneArr[1];
					}
					if (value.aemail != '') {
						var emailArr = value.aemail.split(',');
						this.police.emailone = emailArr[0];
						this.police.emailtwo = emailArr[1];
					}
					if (value.timer1 != '') {
						var time1Arr = value.timer1.split('-');
						this.timing.timeoneH = time1Arr[0];
						this.timing.timeoneM = time1Arr[1];
						this.timing.timeonesH = time1Arr[2];
						this.timing.timeonesM = time1Arr[3];
					}
					if (value.timer2 != '') {
						var time2Arr = value.timer2.split('-');
						this.timing.timetwoH = time2Arr[0];
						this.timing.timetwoM = time2Arr[1];
						this.timing.timetwosH = time2Arr[2];
						this.timing.timetwosM = time2Arr[3];
					}
					if (value.timer3 != '') {
						var time3Arr = value.timer3.split('-');
						this.timing.timethreeH = time3Arr[0];
						this.timing.timethreeM = time3Arr[1];
						this.timing.timethreesH = time3Arr[2];
						this.timing.timethreesM = time3Arr[3];
					}
					for (let key in this.record) {
						if (key == 'srate' || key == 'ndelay') {
							value[key] = value[key] == -1 ? '' : value[key];
						}
						this.record[key] = value[key];
					}
					for (let key in this.engineering) {
						this.engineering[key] = value[key];
					}
					for (let key in this.police) {
						if (key.indexOf('phone') == -1 && key.indexOf('email') == -1) {
							this.police[key] = value[key];
						}
					}
					for (let key in this.instrument) {
						if (value['stype'] == 0) {
							if (key != 'stype' && key != 'sno') {
								value[key] = this.rideNum(value[key], 100);
							}
						} else if (key == 'stype') {
							value[key] = value[key] == -1 ? '' : value[key];
						}
						this.instrument[key] = value[key];
					}
					for (let key in this.network) {
						this.network[key] = value[key];
					}
				}
			});
		},
		// 启动采集状态
		startDevice() {
			var data = {
				did: this.setParameter.did,
				type: 1
			};
			this.axios.post('monitor/control/real', data).then(res => {
				if (res.status) {
					this.setState = 1;
					this.$message({
						showClose: true,
						message: '成功发送启动指令',
						type: 'success'
					});
					this.refreshlist();
				}
			});
		},
		// 停止采集状态
		stopDevice() {
			var data = {
				did: this.setParameter.did,
				type: 2
			};
			this.axios.post('monitor/control/real', data).then(res => {
				if (res.status) {
					this.setState = 0;
					this.$message({
						showClose: true,
						message: '成功发送停止指令',
						type: 'success'
					});
					this.refreshlist();
				}
			});
		},
		// 重启
		restartDevice() {
			var data = {
				did: this.setParameter.did,
				type: 3
			};
			this.axios.post('monitor/control/real', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '成功发送重启指令',
						type: 'success'
					});
				}
			});
		},
		// 清空内存
		empty() {
			var data = {
				did: this.setParameter.did,
				type: 4
			};
			this.axios.post('monitor/control/real', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '成功发送清空内存指令',
						type: 'success'
					});
				}
			});
		},
		// 记录参数
		setrecord() {
			var data = {
				did: this.setParameter.did
			};
			for (let key in this.record) {
				data[key] = this.record[key];
			}
			this.axios.post('monitor/control/mr', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设置成功',
						type: 'success'
					});
				}
			});
		},
		// 工程信息
		setengineering() {
			var data = {
				did: this.setParameter.did
			};
			for (let key in this.engineering) {
				data[key] = this.engineering[key];
			}
			this.axios.post('monitor/control/mp', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设置成功',
						type: 'success'
					});
					this.setParameter.name = this.engineering.pname;
					this.refreshlist();
				}
			});
		},
		// 报警设置
		setpolice() {
			var data = {
				did: this.setParameter.did
			};
			for (let key in this.police) {
				if (key.indexOf('phone') == -1 && key.indexOf('email') == -1) {
					data[key] = this.police[key];
				} else {
					data.aphone = this.police.phoneone + ',' + this.police.phonetwo;
					// data.aemail = this.police.emailone + ',' + this.police.emailtwo;
				}
			}
			var flag = new RegExp("[`~!@#$^&*()=|{}':;'\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ A-Za-z]");
			if (escape(data.aphone).indexOf('%u') < 0) {
				if (flag.test(data.aphone)) {
					this.$message({
						showClose: true,
						message: '测点名称不能含有英文、英文符号或特殊符号！',
						type: 'warning'
					});
					return;
				}
			} else {
				this.$message({
					showClose: true,
					message: '测点名称不能含有中文或中文符号！',
					type: 'warning'
				});
				return;
			}
			this.axios.post('monitor/control/ma', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设置成功',
						type: 'success'
					});
				}
			});
		},
		// 定时开关
		settiming() {
			setTimeout(() => {
				if (!this.timingSet) {
					return;
				}
				var data = {
					did: this.setParameter.did,
					timer1: '',
					timer2: '',
					timer3: ''
				};
				var num1 = 0,
					num2 = 0,
					num3 = 0;
				for (let key in this.timing) {
					if (this.timing[key] != '') {
						if (key.indexOf('one') != -1) {
							data.timer1 = data.timer1 + this.timing[key] + '-';
							if (this.timing[key] != '') {
								num1 += 1;
							}
						}
						if (key.indexOf('two') != -1) {
							data.timer2 = data.timer2 + this.timing[key] + '-';
							if (this.timing[key] != '') {
								num2 += 1;
							}
						}
						if (key.indexOf('three') != -1) {
							data.timer3 = data.timer3 + this.timing[key] + '-';
							if (this.timing[key] != '') {
								num3 += 1;
							}
						}
					}
				}

				if (num1 != 0) {
					if (num1 < 4) {
						this.$message({
							showClose: true,
							message: '请将第一行时间填写完整',
							type: 'error'
						});
						return;
					}
				} else if (num2 != 0) {
					if (num2 < 4) {
						this.$message({
							showClose: true,
							message: '请将第二行时间填写完整',
							type: 'error'
						});
						return;
					}
				} else if (num3 != 0) {
					if (num3 < 4) {
						this.$message({
							showClose: true,
							message: '请将第三行时间填写完整',
							type: 'error'
						});
						return;
					}
				} else {
					this.$message({
						showClose: true,
						message: '请至少填写一行完整的时间',
						type: 'error'
					});
					return;
				}
				for (let key in data) {
					if (key.indexOf('timer') != -1) {
						data[key] = data[key].substr(0, data[key].length - 1);
					}
				}

				this.axios.post('monitor/control/mt', data).then(res => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '设置成功',
							type: 'success'
						});
					}
				});
			}, 100);
		},
		// 仪器配置
		setinstrument() {
			var data = {
				did: this.setParameter.did
			};
			for (let key in this.instrument) {
				if (this.instrument['stype'] == 0) {
					if (key != 'stype' && key != 'sno') {
						data[key] = this.exceptNum(this.instrument[key], 100);
					} else {
						data[key] = this.instrument[key];
					}
				} else {
					data[key] = this.instrument[key];
				}
			}
			this.axios.post('monitor/control/ms', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设置成功',
						type: 'success'
					});
				}
			});
		},
		// 网络参数
		setnetwork() {
			var data = {
				did: this.setParameter.did
			};
			for (let key in this.network) {
				if (key != 'gateway' && key != 'mask' && key != 'hversion' && key != 'sversion') {
					data[key] = this.network[key];
				}
			}
			this.axios.post('monitor/control/mn', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设置成功',
						type: 'success'
					});
				}
			});
		},
		// 数据清除，还原初始值
		clearData(val) {
			var data = this[val];
			for (let key in data) {
				data[key] = '';
			}
		},
		// 当前操作数据更改样式
		theSelected(e) {
			// // console.log($(e.currentTarget));
			var obj = $(e.currentTarget)
				.parent()
				.parent();
			$('.point-box .left-box .content-ul').removeClass('selected-data');
			if (obj.attr('class').indexOf('content-ul') != -1) {
				obj.addClass('selected-data');
			} else {
				obj.parent().addClass('selected-data');
			}
		}
	},
	components: {
		ElImageViewer
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style lang="scss">
// 现场图片预览
.scenePictures {
	margin-top: 15%;
	height: 75%;

	.el-dialog__body {
		width: calc(100% - 30px);
		height: calc(100% - 74px);
		padding: 10px 15px;

		.img-box {
			width: 100%;
			height: 100%;

			.image-slot {
				height: 90%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				i {
					font-size: 50px;
				}

				span {
					font-size: 20px;
				}
			}
		}
	}
}

// 测点列表弹窗
.point-view {
	height: 75%;

	.el-dialog__body {
		width: calc(100% - 30px);
		height: calc(100% - 155px);
		padding: 10px 15px 20px 15px;

		.table-box {
			width: 100%;
			height: 100%;

			.el-table__body-wrapper {
				.el-table__cell {
					padding: 8px 0;
				}
			}

			.imgIcon {
				font-size: 24px;
				color: #409eff;
			}

			.dianliang {
				i {
					font-weight: 600;
					font-size: 22px;
				}
			}

			.icon-style-s {
				font-size: 21px;
			}
			.icon-style-l {
				font-size: 24px;
			}
			.icon-style-xl {
				font-size: 24px;
			}

			.icon-xinhao {
				font-weight: 600;
				font-size: 22px;
			}

			.green {
				color: #00ff7f;
			}

			.grey {
				color: gray;
			}

			.yellow {
				color: #ffaa00;
			}

			.red {
				color: #d90000;
				// font-size: 16px;
				// font-weight: 600;
			}

			.bold {
				font-weight: 600;
				font-size: 16px;
			}
		}
	}
}

// 仪器设置
.instrumentSettings {
	width: 910px;
	height: 500px;

	.color-red {
		color: #f56c6c;
	}

	.tips-size {
		font-size: 12px;
	}

	.tips-text {
		color: #000000;
		letter-spacing: 2px;
	}

	.el-dialog__header {
		font-size: 18px;
		color: #ffffff;

		.el-dialog__headerbtn {
			font-size: 25px;
			top: 14px;
		}
	}

	.el-dialog__body {
		width: calc(100% - 0px);
		height: calc(100% - 74px);
		padding: 0px 0 0 0;
	}

	.content {
		width: 100%;
		height: 100%;

		.button-box {
			padding: 15px 35px 20px 20px;
			border-bottom: thin solid #dcdfe6;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #000;

			.info-box {
				span:first-child {
					margin-right: 10px;
				}
			}
		}

		.main-box {
			width: 100%;
			height: calc(100% - 45px);
			color: #000;

			.el-tabs {
				width: 100%;
				height: 100%;
			}

			.el-tabs__header {
				margin-right: 0;
			}

			.el-tabs__item.is-active {
				color: #409eff;
			}

			.el-tabs__content {
				height: 100%;
			}

			.el-tab-pane {
				height: 100%;
			}

			.el-input__inner {
				height: 32px !important;
				line-height: 32px;
			}

			.el-input__icon {
				line-height: 35px;
			}

			.letter-spacing {
				letter-spacing: 2px;
			}

			.tab-pane-box {
				width: 100%;
				height: 100%;
				position: relative;

				.cell-box {
					height: calc(100% - 55px);
					padding: 15px 0 0 15px;

					.cell {
						display: flex;
						justify-content: flex-start;
						align-items: baseline;
						margin-bottom: 15px;

						.cell-title {
							width: 70px;
						}

						.conent-text {
							width: 165px;
						}

						.unit {
							width: 35px;
							margin-left: 8px;
						}

						.tips {
							margin-left: 8px;
						}

						.cell-child-box {
							.cell-child {
								display: flex;
								justify-content: flex-start;
								align-items: baseline;
								margin-bottom: 10px;

								.cell-child-title {
									width: 33px;
								}
							}

							.margin-bottom-s {
								margin-bottom: 5px !important;
							}
						}
					}

					.tips-cell {
						width: 100% !important;
					}
				}

				.bottomBox {
					width: 100%;
					height: 40px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					position: absolute;
					bottom: 0;
					left: 0;
					border-top: thin solid #dcdfe6;

					button {
						margin-right: 25px;
					}
				}
			}

			// 记录参数
			.record {
				.cell-box {
					.cell {
						.el-input {
							width: 165px;
						}
					}
				}
			}
			// 工程信息
			.engineering {
				.cell-box {
					display: flex;
					justify-content: space-between;
					align-content: flex-start;
					flex-wrap: wrap;

					.cell {
						width: 49%;

						.el-date-editor {
							width: 165px;
						}

						.el-input {
							width: 165px;
						}

						.cell-child-box {
							.el-input {
								width: 132px;
							}
						}
					}
				}
			}
			// 报警设置
			.police {
				.cell-box {
					.cell {
						.tips-info {
							font-size: 12px;
							letter-spacing: 2px;
						}

						.el-input {
							width: 100px;
						}

						.el-checkbox {
							margin-right: 8px;
						}

						.width-s {
							.el-input {
								width: 78px;
							}
						}

						.cell-child-box {
							margin-right: 15px;

							.el-input {
								width: 130px;
							}
						}

						.cell-child-box:last-child {
							.el-input {
								width: 330px;
							}
						}
					}
				}
			}
			// 定时开关
			.timing {
				.cell-box {
					.cell {
						.el-input {
							width: 100px;
						}
						.el-select {
							margin-right: 15px;
						}

						.cell-child-box:last-child {
							.el-input {
								width: 50px;
							}
						}
					}
				}
			}
			// 仪器配置
			.theInstrument {
				.cell-box {
					.cell {
						.el-input {
							width: 165px;
						}

						.cell-title {
							width: 80px;
							padding-right: 8px;
						}

						.cell-child-box {
							border: thin solid #dcdfe6;

							.cell-child {
								margin: 0 !important;

								p {
									display: flex;
									justify-content: center;
									padding: 5px 0;

									.el-input__inner {
										width: 96px;
										height: 25px;
										border: none !important;
									}
								}

								p:nth-child(2) {
									border: thin solid #dcdfe6;
									border-top: none;
									border-right: none;
								}

								.name {
									width: 65px;
								}

								.coefficient {
									width: 96px;
								}

								.border-none {
									border-bottom: none !important;
								}
							}
						}
					}
				}
			}
			// 网络参数
			.network {
				.cell-box {
					display: flex;
					justify-content: space-between;
					align-content: flex-start;
					flex-wrap: wrap;

					.cell {
						width: 49%;

						.cell-title {
							width: 90px;
							padding-right: 8px;
							text-align: right;
							// text-align: justify;
							// text-align-last: justify;
						}

						.title-w-l {
							width: 87px;
						}

						.cell-title-select {
							.el-input {
								width: 95px;
								padding-left: 0;
								border: none;
							}
						}

						.el-checkbox {
							margin-left: 8px;
						}
						.el-input {
							width: 165px;
						}
					}
				}
			}
		}
	}
}
// 批量设置
.volumeSet {
	// width: 575px;
	// height: 400px;
	width: 910px;
	height: 500px;

	.el-dialog__body {
		width: calc(100% - 0px);
		height: calc(100% - 54px);
		padding: 0 0 0 0;
	}

	.content {
		width: 100%;
		height: 100%;

		.button-box {
			padding: 8px 20px 10px 20px;
			border-bottom: thin solid #dcdfe6;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.info-box {
				span:first-child {
					margin-right: 10px;
				}
			}
		}

		.main-box {
			width: 100%;
			height: 100%;

			.el-tabs {
				width: 100%;
				height: 100%;
			}

			.el-tabs__header {
				margin-right: 0;
			}

			.el-tabs__item.is-active {
				color: #409eff;
			}

			.el-tabs__content {
				height: 100%;
			}

			.el-tab-pane {
				height: 100%;
			}

			.el-input__inner {
				height: 32px !important;
				line-height: 32px;
			}

			.el-input__icon {
				line-height: 35px;
			}

			.tips-text {
				letter-spacing: 2px;
			}

			.color-red {
				color: #f56c6c;
			}

			.letter-spacing {
				letter-spacing: 2px;
			}

			.tab-pane-box {
				width: 100%;
				height: 100%;
				position: relative;

				.cell-box {
					height: calc(100% - 55px);
					padding: 15px 0 0 15px;

					.cell {
						display: flex;
						justify-content: flex-start;
						align-items: baseline;
						margin-bottom: 15px;

						.cell-title {
							width: 61px;
						}

						.unit {
							width: 35px;
							margin-left: 8px;
						}

						.tips {
							margin-left: 8px;
						}

						.cell-child-box {
							.cell-child {
								display: flex;
								justify-content: flex-start;
								align-items: baseline;
								margin-bottom: 10px;

								.cell-child-title {
									width: 33px;
								}
							}

							.margin-bottom-s {
								margin-bottom: 5px !important;
							}
						}
					}
				}

				.bottomBox {
					width: 100%;
					height: 40px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					position: absolute;
					bottom: 0;
					left: 0;
					border-top: thin solid #dcdfe6;

					button {
						margin-right: 25px;
					}
				}
			}

			// 记录参数
			.record {
				.cell-box {
					.cell {
						.el-input {
							width: 165px;
						}
					}
				}
			}
			// 报警设置
			.police {
				.cell-box {
					.cell {
						.el-input {
							width: 100px;
						}

						.el-checkbox {
							margin-right: 8px;
						}

						.width-s {
							.el-input {
								width: 78px;
							}
						}

						.cell-child-box {
							margin-right: 15px;

							.el-input {
								width: 130px;
							}
						}

						.cell-child-box:last-child {
							.el-input {
								width: 165px;
							}
						}
					}
				}
			}
			// 定时开关
			.timing {
				.cell-box {
					.cell {
						.el-input {
							width: 100px;
						}

						.el-select {
							margin-right: 15px;
						}

						.cell-child-box:last-child {
							.el-input {
								width: 50px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
<style scoped lang="scss">
.deformationPoint-box {
	width: 100%;
	// height: calc(100% - 61px);
	height: 100%;
	display: flex;
	justify-content: space-between;

	.blue-color {
		color: #409eff;
	}

	.gray-color {
		color: #909399;
	}

	.box-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px 20px 2px 15px;

		.title-text {
			font-size: 17px;
			font-weight: 600;
			color: #303133;
			letter-spacing: 1px;
		}

		.right {
			display: flex;
			justify-content: space-between;
			align-items: center;

			::v-deep {
				.el-input__inner {
					height: 35px;
				}

				.el-input__icon {
					line-height: 35px;
				}
			}

			.button-box {
				margin-left: 25px;

				.el-button + .el-button {
					margin-left: 25px;
				}
			}
		}
	}

	// 列表
	.left-box {
		width: 759px;
		height: 100%;
		flex-shrink: 0;

		.box-title {
			font-size: 17px;
			font-weight: 600;
			color: #303133;
			letter-spacing: 1px;
			margin: 5px 0 0 15px;
		}

		// 当前操作样式
		.selected-data {
			background-color: rgba($color: #c8c8c8, $alpha: 1) !important;
		}

		.list-box {
			width: calc(100% - 15px);
			height: calc(100% - 100px);
			padding: 10px 0 0 15px;

			ul {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				li {
					flex-shrink: 0;
					border-right: thin solid #dcdfe6;
					text-align: center;

					i {
						margin-left: 2px;
					}

					i:first-child {
						margin-left: 0;
					}

					.icon-style-s {
						font-size: 21px;
					}
					.icon-style-l {
						font-size: 24px;
					}
					.icon-style-xl {
						font-size: 24px;
					}
					// 电量样式
					.electric {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						.electric-box {
							width: 100%;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;

							i {
								margin-left: 0;
							}
						}
					}

					.charge {
						font-size: 24px !important;
						font-weight: 500;
					}

					.battery {
						font-size: 24px !important;
					}
					// 结束

					.iconzu-box {
						display: inline-block;
						width: 24px;
						text-indent: 0;
						text-align: center;
					}

					.iconzu-box-l {
						display: inline-block;
						width: 35px;
						text-indent: 0;
						text-align: center;
					}

					.margin-style {
						margin: 0 8px;
					}
				}

				li:first-child {
					border-left: thin solid #dcdfe6;
				}

				.text-left {
					text-indent: 6px;
					text-align: left !important;
				}

				.name {
					text-decoration: underline wavy #3ad1ff;
				}

				.text-color {
					color: #409eff;
				}

				.check-box {
					width: 30px;
					text-indent: 0 !important;
					// display: flex;
					// justify-content: center;
					// align-items: center;

					::v-deep {
						.el-checkbox__label {
							display: none;
						}

						.el-checkbox__inner {
							width: 18px;
							height: 18px;
						}

						.el-checkbox__inner::after {
							height: 8px;
							top: 2px;
							left: 6px;
						}
					}
				}

				.width-xs {
					width: 60px;
				}

				.width-s {
					width: 45px;
				}

				.file {
					width: 65px;
				}

				.alert {
					width: 85px;
				}

				.width-sl {
					width: 120px;
				}

				.width-xl {
					width: 120px;
				}

				.set-up-li {
					width: 100px;
				}

				.address {
					width: 200px;
				}

				.img-li {
					width: 65px;
				}

				.dianliang {
					i {
						font-weight: 600;
						font-size: 22px;
					}
				}

				.icon-xinhao {
					font-weight: 600;
					font-size: 22px;
				}

				.green {
					color: #00ff7f;
				}

				.grey {
					color: gray;
				}

				.yellow {
					color: #ffaa00;
				}

				.red {
					color: #d90000;
					// font-size: 16px;
					// font-weight: 600;
				}

				.bold {
					font-weight: 600;
					font-size: 16px;
				}
			}

			// 排序
			.sort-box {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.sort {
					width: 30px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					line-height: initial;
					cursor: pointer;

					span {
						font-size: 14px;
						display: none;
					}

					.up,
					.up-full {
						margin-bottom: -5px;
					}

					.down,
					.down-full {
						margin-top: -5px;
					}

					.show {
						display: inline-block;
					}
				}
			}

			.heard-ul {
				height: 45px;
				// background-color: #87cbcb;
				background-color: #ebeef5;
				line-height: 45px;
				font-size: 15px;
				font-weight: 600;
				color: #4c4e51;
				letter-spacing: 1px;
			}

			.list-scroll {
				width: 100%;
				height: calc(100% - 8px);
				transform: scale(1); //此处是为了让统计那一行相对于这个元素定位

				::v-deep {
					.__view {
						width: 100% !important;

						.content-ul:nth-last-child(1) {
							border-bottom: thin solid #dcdfe6;
						}
					}
				}

				.indent-none {
					text-indent: 0 !important;
				}

				.content-ul:nth-child(odd) {
					background-color: #ffffff;
				}

				.content-ul:nth-child(even) {
					background-color: #f2f2f2;
				}

				.content-ul:hover {
					background-color: #ebeef5;
				}

				.content-ul {
					height: 39px;
					line-height: 39px;
					font-size: 14px;
					color: #606266;
					border-top: thin solid #dcdfe6;

					li {
						height: 100%;
						text-indent: 6px;
					}
				}
				// 统计
				.statistical-ul {
					height: 35px;
					line-height: 35px;
					font-size: 15px;
					font-weight: 600;
					color: #4c4e51;
					letter-spacing: 1px;
					border-top: thin solid #dcdfe6;
					border-bottom: thin solid #dcdfe6;
					background-color: #ebeef5;

					li {
						height: 100%;
					}
				}
			}
		}
	}
	// 图片
	.right-box {
		width: calc(100% - 800px);
		height: 100%;
		margin: 0px 5px 0 10px;

		// 项目描述
		.info {
			width: 100%;
			padding: 20px 0 2px 0;

			.info-title {
				margin: 0 0 16px 15px;
				font-size: 17px;
				font-weight: 600;
				color: #303133;
				letter-spacing: 1px;
			}

			.info-p {
				width: 100%;
				height: 176px;
				border: thin solid #e4e7ed;
				margin-top: 8px;

				.info-content {
					font-size: 14px;
					text-indent: 2em;
					letter-spacing: 2px;
				}
			}
		}
		// 测点布设图
		.img-content {
			width: 100%;
			height: calc(100% - 240px);

			.right-box-title {
				display: flex;
				justify-content: flex-start;
				padding: 18px 20px 2px 15px;

				.title {
					font-size: 17px;
					font-weight: 600;
					color: #303133;
					letter-spacing: 1px;
				}
			}

			.img-box {
				width: calc(100% - 6px);
				height: calc(100% - 71px);
				border: 3px solid #ebeef5;
				margin-top: 19px;

				::v-deep {
					.image-slot {
						height: 90%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						i {
							font-size: 140px;
						}

						span {
							font-size: 26px;
						}
					}
				}
			}
		}
	}
}
</style>
